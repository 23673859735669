import { useQueryClient } from "@tanstack/react-query"

import { PointMetrics } from "src/data/devices/logic/deviceConstants"
import {
  IParadiseDeviceEventsFilter,
  IParadiseDeviceListFilter,
  IParadiseDeviceMetricsFilter,
  IParadiseDevicePointMessagesFilter,
} from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"

export const paradiseDevicesKeys = {
  all() {
    return ["paradiseDevices"] as const
  },

  deviceLists() {
    return [...this.all(), "list"] as const
  },

  deviceList(filter?: IParadiseDeviceListFilter) {
    if (!filter) {
      return [...this.deviceLists()] as const
    }

    return [...this.deviceLists(), filter] as const
  },

  device(deviceId: string) {
    return [...this.all(), deviceId] as const
  },

  pointMessages(deviceId: string, filter?: IParadiseDevicePointMessagesFilter) {
    if (!filter) {
      return [...this.device(deviceId), "pointmessages"] as const
    }

    return [...this.device(deviceId), "pointmessages", filter] as const
  },

  pointMessage(deviceId: string, timestamp: number, messageType: number) {
    return [...this.pointMessages(deviceId), timestamp, messageType] as const
  },

  warrantyStatus(deviceId: string) {
    return [...this.device(deviceId), "warrantystatus"] as const
  },

  deviceEvents(deviceId: string, filter?: IParadiseDeviceEventsFilter) {
    if (!filter) {
      return [...this.device(deviceId), "deviceevents"] as const
    }

    return [...this.device(deviceId), "deviceevents", filter] as const
  },

  deviceMetrics(
    deviceId: string,
    metricId: PointMetrics,
    filter: IParadiseDeviceMetricsFilter
  ) {
    return [
      ...this.device(deviceId),
      "deviceMetrics",
      metricId,
      filter,
    ] as const
  },

  count() {
    return [...this.all(), "count"] as const
  },

  hardwareVersions() {
    return [...this.all(), "hardwareVersions"] as const
  },
} as const

export function useParadiseDeviceCache() {
  const queryClient = useQueryClient()

  function invalidateParadiseDevice(deviceId: string) {
    const cacheKey = paradiseDevicesKeys.device(deviceId)

    return queryClient.invalidateQueries({ queryKey: cacheKey })
  }

  function invalidateParadiseDeviceList(filter?: IParadiseDeviceListFilter) {
    const cacheKey = paradiseDevicesKeys.deviceList(filter)

    return queryClient.invalidateQueries({ queryKey: cacheKey })
  }

  return {
    invalidateParadiseDevice,
    invalidateParadiseDeviceList,
  }
}
